.BankTransferDonate {
  padding: 100px 0;
}



.BankTransferDonateContent span {
  margin: 0 auto;
  color: var(--AccentColor, #0EA04D);
  font-family: Tajawal;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.86px;
  line-height: normal;
  display: flex;
  width: 32%;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.underLineTitle {
  margin: 0 auto;
}

.bankDataMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-shadow: 0px 1px 40px 0px #283F741A;
  max-width: 945px;
  height: 180px;
  gap: 30px;
  margin-bottom: 16px;
}

.bankInfo {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 8px;
  width: 35%;
}

.bankInfo>span {
  font-family: Tajawal;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  color: #232F4B;
}


.bankInfo>div {
  font-family: Tajawal;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  color: #232F4B;
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

.bankInfo>div>span {
  font-family: Tajawal;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #828282;
  direction: rtl;
}

@media (max-width:992px) {
.bankDataMainContainer
{
    flex-direction: column-reverse;
    height: 260px;
    padding-bottom: 3px;
}
.bankInfo
{
  width: 85%;
}
}