.Inkind-Donation {
    padding: 100px 0;
}

.donatecontainer {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 20px;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1120px;
    background-color: #ffffff;
}

.DonateContent p {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #13383E;

}


.content {
    flex: 1;
    /* padding-left: 20px; */
    direction: rtl;
    text-align: right;
    max-width: 469px;
    max-height: 467px;
    justify-content: right;





}

.Inkind-DonationContent span {
    margin: 0 auto;
    color: var(--AccentColor, #0EA04D);
    font-family: Tajawal;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.86px;
    line-height: normal;
    display: flex;
    width: 32%;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.donateForm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.underLineTitle {
    margin: 0 auto;
}

.donation-heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #232F4B;
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: center;

}

.donation-paragraph {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #13383E;
}

.quote {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #828282;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}



.Hadeeth {
    color: #13921C;
}



#date,
.other {
    padding: 15px;
    border-radius: 6px;
    background-color: #F4F8FC;
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    width: 80%;
}

.donate-button {
    padding: 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    border-radius: 6px;
    background-color: #13921C;
    color: #FFFFFF;
    border: none;
    margin-top: 40px;
    width: 100%;
}

.other {
    padding: 10px;
    background-color: #F4F8FC;
    border: none;
}

.image-container {

    margin-top: 40px;


}

.image {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    max-height: 467px;

}