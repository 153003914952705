/*---------------------------
  Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Rubik:300,400,500,700&display=swap');




/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
    font-size: 16px;
}

/* *
{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
} */
body {
    font-family: "Open Sans", sans-serif !important;
    background-color: #F4F8FC !important;
    font-size: 16px;
    font-size: 1rem;
    color: #898888;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

@media (max-width: 767px) {
    body {
        font-size: 14px;
    }
}

p {
    color: #898888;
    line-height: 1.6em;
    font-size: 16px;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rubik', sans-serif;
    color: #c3bfbf;
}

ul {
    padding-left: 0;
    margin: 0 !important;
    list-style: none;
}

a {
    text-decoration: none !important;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    color: #ddd;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.page-wrapper {
    position: relative;
    overflow: hidden;
}

.wow {
    visibility: hidden;
}

.App {
    overflow-x: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 100px 0;
}

@media (max-width: 991px) {
    .section-padding {
        padding: 90px 0;
    }
}

@media (max-width: 767px) {
    .section-padding {
        padding: 60px 0;
    }
}

/*** contact form error handling ***/
.contact-validation-active .error-handling-messages {
    width: 100% !important;
    margin-top: 15px !important;
}

.contact-validation-active label.error {
    color: red;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 5px 0 0 0;
    text-align: left;
    display: block;
}

.contact-validation-active #loader {
    display: none;
    margin-top: 10px;
}

.contact-validation-active #loader i {
    font-size: 30px;
    font-size: 1.875rem;
    color: #13921C;
    display: inline-block;
    -webkit-animation: rotating linear 2s infinite;
    animation: rotating linear 2s infinite;
}

.contact-validation-active #success,
.contact-validation-active #error {
    width: 100%;
    color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    text-align: center;
    display: none;
}

@media (max-width: 767px) {

    .contact-validation-active #success,
    .contact-validation-active #error {
        font-size: 15px;
    }
}

.contact-validation-active #success {
    background-color: #009a00;
    border-left: 5px solid green;
    margin-bottom: 5px;
}

.contact-validation-active #error {
    background-color: #ff1a1a;
    border-left: 5px solid red;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 5s alternate infinite;
    animation: rotating 5s alternate infinite;
}

/** for popup image ***/
.mfp-wrap {
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}

.tp-section-title {
    text-align: center;
    position: relative;
    max-width: 500px;
    margin: auto;
    margin-bottom: 70px;
}



.tp-section-title span {
    font-size: 18px;
    font-weight: 400;
    color: #13921C;
    display: block;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
}

.tp-section-title h2 {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    display: inline-block;
    position: relative;
    line-height: 50px;
}

@media (max-width: 991px) {
    .tp-section-title h2 {
        font-size: 45px;
        font-size: 2.8125rem;
    }
}

@media (max-width: 767px) {
    .tp-section-title h2 {
        font-size: 25px;
        line-height: 35px;
    }
}

.theme-btn,
.theme-btn-s2,
.theme-btn-s4,
.theme-btn-s3 {
    background-color: #13921C;
    color: #fff;
    font-weight: 700;
    padding: 13px 38px;
    border-radius: 5px;
    display: inline-block;
    transition: all .3s;
}

@media (max-width: 991px) {

    .theme-btn,
    .theme-btn-s2,
    .theme-btn-s4,
    .theme-btn-s3 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 20px;
    }
}


.theme-btn:hover,
.theme-btn-s2:hover,
.theme-btn-s4:hover,
.theme-btn-s3:hover,
.theme-btn:focus,
.theme-btn-s2:focus,
.theme-btn-s4:focus,
.theme-btn-s3:focus,
.theme-btn:active,
.theme-btn-s2:active,
.theme-btn-s4:active,
.theme-btn-s3:active {
    background-color: #13921C;
    color: #fff;
}

.theme-btn-s4 {
    font-weight: 400;
    color: #fff;
    transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
}

.theme-btn-s2 {
    border: none;
    background: none;
    border: 1px solid #fff;
    color: #fff;
}

.theme-btn-s2:hover,
.theme-btn-s4:hover,
.theme-btn-s2:focus,
.theme-btn-s4:focus,
.theme-btn-s2:active,
.theme-btn-s4:active {
    background-position: 100% !important;
    color: #fff;
}

.theme-btn-s3 {
    padding: 13px 30px;
}

@media (max-width: 991px) {
    .theme-btn-s3 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.theme-btn-s4 {
    padding: 15px 48px;
    border-radius: 55px;
}

@media (max-width: 991px) {
    .theme-btn-s4 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.view-cart-btn {
    display: block;
    position: relative;
}

.social-links {
    overflow: hidden;
}

.social-links li {
    float: left;
    width: 35px;
    height: 35px;
    margin-right: 1px;
}

.social-links li a {
    background-color: #05f2ff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: block;
    color: #fff;
    text-align: center;
}

.social-links li a:hover {
    background-color: #13921C;
}


/*** back to top **/
.back-to-top {
    background-color: rgba(254, 108, 53, 0.7);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 2px solid #13921C;
    border-radius: 45px;
    text-align: center;
    display: none;
    position: fixed;
    z-index: 999;
    right: 15px;
    bottom: 15px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

@media (max-width: 991px) {
    .back-to-top {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }
}

.back-to-top:hover {
    background-color: #13921C;
}

.back-to-top i {
    font-size: 18px;
    font-size: 1.125rem;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}



.modal-video-close-btn {
    top: -20px;
    right: -20px;
    width: 35px;
    height: 35px;
    background: #13921C;
    border-radius: 50%;
}

.modal-video-close-btn:before, .modal-video-close-btn:after {
    top: 65%;
}

.errorMessage {
    font-size: 12px;
    margin-top: 5px;
    color: red;
}

.cBtnTheme {
    background: #13921C;
}


.fixed-navbar {
    position: relative;
    z-index: 5555;
}

.fixed-navbar.active .header-style-1,
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
    position: fixed;
    z-index: 555;
    top: 0;
    opacity: 1;
    left: 0;
    width: 100%;
    border: none;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
}