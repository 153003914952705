/*--------------------------------------------------------------
#0.5  tp-footer
--------------------------------------------------------------*/
.tp-site-footer,
.tp-site-footer {
    background: #F4F8FC;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    direction: rtl;
}

.about-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tp-ne-footer {
    background: #062265;
}

.tp-site-footer-2 {
    background: #232F4B;
}

.tp-site-footer ul,
.tp-site-footer-2 ul {
    list-style: none;
    text-align: right;
}

.contactUsInfoFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contactInfo {
    display: flex;
    gap: 16px;
    align-items: start;
    text-align: right;
}


.tp-site-footer p,
.tp-site-footer li {
    color: #232F4B;
}

.copyright {
    color: white !important;
}

.tp-site-footer-2 p,
.tp-site-footer-2 li {
    color: #444444;
}

.tp-site-footer .container,
.tp-site-footer-2 .container {
    position: relative;
}

.tp-site-footer .tp-upper-footer,
.tp-site-footer-2 .tp-upper-footer {
    padding: 90px 0;

}

@media (max-width: 991px) {

    .tp-site-footer .tp-upper-footer,
    .tp-site-footer-2 .tp-upper-footer {
        padding: 60px 0 0;
    }
}

@media (max-width: 767px) {

    .tp-site-footer .tp-upper-footer,
    .tp-site-footer-2 .tp-upper-footer {
        padding: 60px 0 0;
    }
}

@media (max-width: 991px) {

    .tp-site-footer .tp-upper-footer .col,
    .tp-site-footer-2 .tp-upper-footer .col {
        min-height: 235px;
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {

    .tp-site-footer .tp-upper-footer .col,
    .tp-site-footer-2 .tp-upper-footer .col {
        min-height: auto;
        margin-bottom: 60px;
    }
}

.tp-site-footer .widget-title,
.tp-site-footer-2 .widget-title {
    text-align: right;
}

@media (max-width: 767px) {

    .tp-site-footer .widget-title,
    .tp-site-footer-2 .widget-title {
        margin-bottom: 20px;
    }
}

.tp-site-footer .widget-title h3,
.tp-site-footer-2 .widget-title h3 {
    font-size: 22px;
    font-weight: bold;
    color: #232F4B;
    margin: 0;
    text-transform: capitalize;
    position: relative;
}

.tp-site-footer-2 .widget-title h3 {
    color: #2d4277;
}

.tp-site-footer .logo {
    margin-top: 0;
}

.footerLogoContainer {
    display: flex;
    align-items: center;
    gap: 30px;
}

.footerLogoContainer img {
    width: 88px;
    height: 88px;
}

.ZakatCommitteeFooterStyle {
    font-family: Inter;
    font-size: 18.87px;
    font-weight: 600;
    line-height: 22.83px;
    color: #13921C;
}

.ZakatCommitteeDescFooterStyle {
    font-family: Inter;
    font-size: 10.92px;
    font-weight: 400;
    line-height: 13.22px;
    color: #26292E;

}

@media (max-width: 991px) {

    .tp-site-footer .widget-title h3,
    .tp-site-footer-2 .widget-title h3 {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

.tp-site-footer .about-widget .logo {
    max-width: 180px;
}

.tp-site-footer .about-widget p,
.tp-site-footer-2 .about-widget p {
    margin-bottom: 0.8em;
    line-height: 1.9em;
    text-align: right;
}

.tp-site-footer .about-widget p:last-child,
.tp-site-footer-2 .about-widget p:last-child {
    margin-bottom: 0;
}

.tp-site-footer .about-widget ul,
.tp-site-footer-2 .about-widget ul {
    overflow: hidden;
    padding-top: 10px;
    text-align: right;
}

ul {
    padding-inline-start: 0px !important;
}

.tp-site-footer .about-widget ul li,
.tp-site-footer-2 .about-widget ul li {
    font-size: 22px;
    float: right;
}

.tp-site-footer .about-widget ul li .parallax-wrap,
.tp-site-footer .about-widget ul li .parallax-element {
    width: unset;
    height: unset;
}

.tp-site-footer .about-widget ul>li+li,
.tp-site-footer-2 .about-widget ul>li+li {
    margin-right: 25px;
}

.tp-site-footer .about-widget ul a {
    color: #232F4B;
}

.tp-site-footer-2 .about-widget ul a {
    color: #404040;
}

.tp-site-footer .about-widget ul a:hover,
.tp-site-footer-2 .about-widget ul a:hover {
    color: #13921C;
}

.tp-site-footer-2 .about-widget ul a {
    color: #13921C;
}

.tp-site-footer .link-widget,
.tp-site-footer-2 .link-widget {
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .tp-site-footer .link-widget {
        padding-right: 20px;
    }

    .tp-site-footer-2 .link-widget {
        padding-right: 10px;
    }
}

@media (max-width: 1199px) {

    .tp-site-footer .link-widget,
    .tp-site-footer-2 .link-widget {
        padding-right: 20px;
    }
}

@media (max-width: 991px) {

    .tp-site-footer .link-widget,
    .tp-site-footer-2 .link-widget {
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    .tp-site-footer .about-widget ul {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }

    .tp-site-footer .link-widget,
    .tp-site-footer-2 .link-widget {
        max-width: 350px;
    }
}

.tp-site-footer .link-widget ul li,
.tp-site-footer-2 .link-widget ul li {
    position: relative;
}

.tp-site-footer .link-widget ul a,
.tp-site-footer-2 .link-widget ul a {
    color: #232F4B;
}

.tp-site-footer-2 .link-widget ul a {
    color: #404040;
}

.tp-site-footer .link-widget ul a:hover,
.tp-site-footer-2 .link-widget ul a:hover,
.tp-site-footer .link-widget ul li:hover:before,
.tp-site-footer-2 .link-widget ul li:hover:before {
    text-decoration: underline;
}

.tp-site-footer .link-widget ul>li+li,
.tp-site-footer-2 .link-widget ul>li+li {
    margin-top: 15px;
}

@media screen and (min-width: 1200px) {
    

    .tp-site-footer .resource-widget,
    .tp-site-footer-2 .resource-widget {
        padding-right: 0px;
    }
}

.tp-site-footer .contact-ft ul li .fi:before,
.tp-site-footer-2 .contact-ft ul li .fi:before {
    font-size: 20px;
    margin-right: 15px;
}

.tp-site-footer .contact-ft ul li,
.tp-site-footer-2 .contact-ft ul li {
    padding-bottom: 10px;
}

.tp-site-footer .contact-ft,
.tp-site-footer-2 .contact-ft {
    margin-top: 20px;
}

.tp-site-footer .tp-lower-footer,
.tp-site-footer-2 .tp-lower-footer {
    text-align: center;
    position: relative;
    background: #262525;
}

.tp-site-footer .tp-lower-footer .row,
.tp-site-footer-2 .tp-lower-footer .row {
    padding: 20px 0;
    position: relative;
}


.tp-site-footer .tp-lower-footer .copyright,
.tp-site-footer-2 .tp-lower-footer .copyright {
    display: inline-block;
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0;
}

@media (max-width: 991px) {

    .tp-site-footer .tp-lower-footer .copyright,
    .tp-site-footer-2 .tp-lower-footer .copyright {
        float: none;
        display: block;
    }
}

.tp-site-footer .tp-lower-footer .copyright a,
.tp-site-footer-2 .tp-lower-footer .copyright a {
    color: #232F4B;
    text-decoration: underline;
}

.instagram ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -3px;
}

.instagram ul li {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 5px;
    padding: 0px 3px;
}

.instagram ul li img {
    width: 83.48px;
    height: 83.48px;
}




@media(max-width: 1200px) {
    .tp-site-footer .news-text h3 {
        font-size: 13px;
    }

    .tp-site-footer .contact-ft ul li,
    .tp-site-footer-2 .contact-ft ul li {
        padding-bottom: 10px;
        font-size: 13px;
    }
       
}