/*================================================
#1.2 tp-masajidServices-area
==================================================*/

.MasajidServices-section {
  background: white;
  padding-bottom: 80px;
}

.masajidServices-grids {
  margin: 0 -15px;
  display: flex;
  justify-content: center;
  gap: 32px;

}

/* .donate-now-wrapper {
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
} */

.joinProjectBtn {
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  box-sizing: border-box;
  text-align: center;
  width: 235px;
  padding: 9px 32px;
  border-radius: 30px;
  background: rgba(51, 66, 66, 0.72);
  box-shadow: 0px 0px 8px 0px #BAC2CB;
  color: #FFF;
  text-align: center;
  font-family: Tajawal;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.86px;
  margin: 0 auto;
}

.joinProjectBtn:hover {
  color: white;
}

.joinProjectsTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.joinProjectsTitle span {
  color: #FFF;
  text-align: center;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}


.joinProjectsContent {
  color: #E7E7E7;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  border-bottom: 2px solid #FFFFFF;
  padding-bottom: 40px;
}

.cardCounterContainer {
  display: flex;
  border-radius: 24px;
  border: 2px solid #13921C;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 48px;
}

.cardDonateValueContainer {
  display: flex;
  border-radius: 24px;
  border: 2px solid #13921C;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 48px;
}

.cardIncrementDecrementBtn {
  width: 40px;
  height: 40px;
  padding: var(--Count, 0px);
  border-radius: 24px;
  background: rgba(215, 215, 215, 0.16);
  color: var(--AccentColor, #13921C);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}

.cardCounterValue {
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardMainContainer {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.cardContainer span {
  color: var(--AccentColor, #13921C);
  text-align: center;
  font-family: Tajawal;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.submitDonateCardBtn {
  display: flex;
  width: 100%;
  height: 54px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #13921C;
  color: var(--White, #FFF);
  text-align: center;
  font-family: Tajawal;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 32px;
}

.submitDonateCardBtn:hover {
  color: white;
}


@media (max-width: 991px) {
  .masajidServices-grids {
    flex-direction: column;
  }

  .MasajidServices-section {
    padding-bottom: 70px;
  }

  .cardCounterContainer {
    width: 100px;
  }

  .cardDonateValueContainer {
    width: 100px;
  }
}

@media (max-width: 767px) {
  .masajidServices-grids {
    flex-direction: column;
  }

  .MasajidServices-section {
    padding-bottom: 60px;
  }

  .cardCounterContainer {
    width: 100px;
  }

  .cardDonateValueContainer {
    width: 100px;
  }
}



.MasajidServices-section .img-holder {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
}

.MasajidServices-section .img-holder img {
  border-radius: 20px;
  transform: scale(1);
  transition: all .3s;
  width: 328px;
  height: 272px;
}

/* .MasajidServices-section .grid:hover .img-holder img{
  transform: scale(1.2);
} */
.MasajidServices-section .details {
  background-color: #101010;
  text-align: center;
  padding: 65px 15px 20px;
  margin-top: -35px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.MasajidServices-section .details h3 {
  font-size: 22px;
  margin: 0 0 0.2em;
}

.MasajidServices-section .details h3 a {
  color: #ddd;
}

.MasajidServices-section .details h3 a:hover {
  color: #13921C;
}

@media (max-width: 991px) {
  .masajidServices-grids {
    flex-direction: column;
  }

  .MasajidServices-section .details h3 {
    font-size: 18px;
  }

  .cardCounterContainer {
    width: 100px;
  }

  .cardDonateValueContainer {
    width: 100px;
  }
}

.MasajidServices-section .details p {
  margin: 0;
}

@media (max-width: 991px) {
  .MasajidServices-section .details p {
    font-size: 14px;
  }

  .cardCounterContainer {
    width: 100px;
  }

  .cardDonateValueContainer {
    width: 100px;
  }

  .masajidServices-grids {
    flex-direction: column;
  }
}