ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right: 30px;
    list-style: none;
    z-index: 99;
}

ul.smothscroll a {
    background: #13921C;
    width: 45px;
    height: 45px;
    line-height: 40px;
    border-radius: 3px;
    display: block;
    text-align: center;
    color: #fff;
    background-size: 200%, 1px;
    background-position: 0;
    border-radius: 50%;
    border: 2px solid #13921C;
}

ul.smothscroll a:hover {
    background: #13921C;
    color: #fff;
}

@media(max-width:767px) {
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}