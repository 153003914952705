/*-----------------------------------------------------------------
breadcumb-style
------------------------------------------------------------------*/

.tp-breadcumb-area {

  min-height: 350px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.tp-breadcumb-area:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .2);
  content: "";
  z-index: -1;
}

.tp-breadcumb-wrap {
  text-align: center;
  direction: rtl;
}

@media(max-width: 767px) {
  .tp-breadcumb-area {
    min-height: 250px;
  }


}

.tp-breadcumb-wrap h2 {
  font-size: 40px;
  color: #fff;
  line-height: 60px;
  margin-top: -10px;
}

@media(max-width: 767px) {
  .tp-breadcumb-wrap h2 {
    font-size: 25px;
    line-height: 35px;
  }
}

.tp-breadcumb-wrap ul li {
  display: inline-block;
  padding: 0px 5px;
  color: #fff;
}

.tp-breadcumb-wrap ul li a {
  color: #fff;
  font-size: 18px;
  transition: all .3s;
}

.tp-breadcumb-wrap ul li a:hover {
  color: #13921C;
}

.tp-breadcumb-wrap ul li:last-child {
  color: #13921C;
}

.tp-breadcumb-wrap ul li:after {
  content: "/";
  position: relative;
  right: 7px;
}

.tp-breadcumb-wrap ul li:last-child:after {
  display: none;
}