/*--------------------------------------------------------------
#0.6 news-letter-part
--------------------------------------------------------------*/
.news-letter-section {
  text-align: center;
  position: relative;
  z-index: 1;
  padding-top: 30px;
  /* bottom: -300px; */
}

.contactUsEmailTitle {
  color: #232F4B;
  text-align: center;
  font-family: Tajawal;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 58.5px;
  /* 130% */
}

.news-letter-section:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  content: "";
  background: #F4F8FC;
  z-index: -1;

}

.news-letter-wrap {
  max-width: 970px;
  margin: auto;
  border-radius: 24px;
  background: #F4F8FC;
  box-shadow: 0px 4px 9.5px 0.5px rgba(53, 53, 53, 0.10);
  padding: 60px;
}

@media (max-width: 991px) {
  .news-letter-section {
    padding: 0;
  }
}

.news-letter-section h3 {
  font-size: 45px;
  line-height: 1.3em;
  color: #fff;
  font-weight: 600;
  margin: 0 auto 0.5em;
}

.news-letter-section p {
  margin: 0 0 2em;
}

@media (max-width: 1024px) {
  .news-letter-section h3 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 991px) {
  .news-letter-section h3 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media (max-width: 767px) {
  .news-letter-section h3 {
    font-size: 18px;
  }

  .news-letter-wrap {
    padding: 30px;
  }
}

.news-letter-section form,
.news-letter-section-2 form {
  max-width: 425px;
  margin: 0 auto;
}

.news-letter-section form>div {
  position: relative;
}



.bigCursor {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 45px;
  padding: var(--Count, 12px) var(--Count, 37.084px) var(--Count, 11px) var(--Count, 37.4px);
  gap: var(--Count, 0px);
  border-radius: 8px;
  background: #13921C;
  color: #FFF;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  border: none;
  text-align: center;
  align-items: center;
}

#messageContent {
  width: 100%;
  height: 125px;
  padding: var(--Count, 18px) var(--Count, 15px) var(--Count, 73px) var(--Count, 25px);
  gap: var(--Count, 0px);
  text-align: right;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.10);
  resize: none;
  border: none;
  margin-top: 8px;
}

.news-letter-section form>div input {
  width: 100%;
  height: 55px;
  padding: var(--Count, 18px) var(--Count, 15px) var(--Count, 18px) var(--Count, 25px);
  gap: var(--Count, 0px);
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 68, 0.10);
  text-align: right;
}

@media (max-width: 991px) {
  .news-letter-section form>div input {
    height: 50px;
  }
}

.news-letter-section form>div button {
  background: #13921C;
  width: 150px;
  height: 45px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  border: 0;
  outline: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 45px;
  line-height: 45px;
}

@media (max-width: 991px) {
  .news-letter-section form>div button {
    width: 120px;
    height: 40px;
    line-height: 40px;
  }
}


.news-letter-section.s2 {
  text-align: center;
  position: relative;
  z-index: 1;
  background: white;
}