.mobileMenu {
    position: fixed;
    left: -270px;
    top: 0;
    z-index: 1500;
    height: 100vh;
    width: 270px;
    background: #171a17;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    overflow-x: auto;
    overflow-y: scroll;
}

.responsivemenu .card-body {
    background: #171a17;
}

.mobileMenu h2 {
    padding-left: 20px;
}

.mobileMenu.show {
    left: 0;
}

.showmenu {
    position: absolute;
    left: 0px;
    top: 12px;
    z-index: 999;
    cursor: pointer;
}


.responsivemenu {
    list-style: none;
    padding-left: 0;
    padding-top: 30px;
}

.closeSideMenuBtn
{
    display: flex;
    justify-content: right;
    padding-right: 20px;
    padding-top: 10px;
    cursor: pointer;
}


.FinancialDonationSideMenu
{
    width: 123px;
	height: 40px;
	padding: 9px, 32px, 9px, 32px;
	border-radius: 5px;
	background-color: transparent;
	color: black;
	font-family: Tajawal;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 22.86px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 1px solid black;
    background-color: white !important;
}
.responsivemenu li a,
.responsivemenu li p {
    display: block;
    padding: 13px 20px;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #898888;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.responsivemenu li p {
    position: relative;
    margin-bottom: 0;
}

.responsivemenu .card {
    border: none
}

.responsivemenu .card-body {
    padding-top: 0;
}

.responsivemenu .card-body li {
    background: #171a17;
}

.responsivemenu li a {
    position: relative;
}

.responsivemenu li i {
    position: absolute;
    right: 20px;
    top: 17px;
}

.showmenu i {
    font-size: 30px;
    color: #fff;
}

.mobileMenu {
    display: none;
}

.showmenu {
    display: none;
}

@media (max-width:992px) {
    .showmenu {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        background: #13921C;
        line-height: 50px;
        border-radius: 5px;
    }

    .mobileMenu {
        display: block;
    }
}


.middle-header-3 .showmenu {
    top: 36px;
}

.middle-header2 .showmenu {
    top: 26px;
}

.middle-header-3 .showmenu i {
    color: #fff;
}


@media(max-width:450px) {
    .mobileMenu {
        left: -240px;
        width: 240px;
    }

    .responsivemenu li a, .responsivemenu li p {
        font-size: 14px;
    }

}