.masajidServicesCardMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: var(--Count, 0px);
    background: #FFF;
    width: 360px;
    height: 515px;
    text-align: center;
    border-radius: 16px;
    border: 2px solid #E9EAEB;
    margin-bottom: 16px;
    cursor: pointer;
}

.servicesMainContainer {
    display: flex;
    justify-content: center;
}

.masajidServicesCardMainContainer img {
    height: 379px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.serviceCardFooter {
    width: 100%;
    height: 100%;
    padding-top: 16px;
    background: #F4F8FC;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.serviceCardFooter span {
    color: #232F4B;
    font-family: Tajawal;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30.8px;
    /* 140% */
}

.serviceCardFooter div {
    color: #666;
    text-align: center;
    font-family: Tajawal;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
}