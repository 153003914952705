.sectionCard
{
  display: flex;
    padding: 49px var(--Count, 0px);
    flex-direction: column;
    align-items: center;
    gap: 40px;
  border-radius: var(--Count, 0px);
  background: #FFF;
  box-shadow: 0px 1px 40px 0px rgba(40, 63, 116, 0.10);
  margin: 20px;
}